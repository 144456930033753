@import "../../styles/tools";

$loader-color: $color-green;
$loader-color--inverted: #fff;
$loader-color--grey: #64696C;

@mixin build-loader-size-with-after($size, $margin, $width) {
    width: $size + $margin * 2 + $width * 2;
    height: $size + $margin * 2 + $width * 2;

    &::after {
        width: $size;
        height: $size;
        margin: $margin;
        border-width: $width;
    }
}

@mixin build-loader-size($size, $margin, $width) {
    width: $size;
    height: $size;
    margin: $margin;
    border-width: $width;
}

.loader-two-sides-circle {
    display: inline-block;
    width: 80px;
    height: 80px;

    &::after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border-width: 6px;
        border-style: solid;
        border-color: $loader-color transparent $loader-color transparent;
        animation: loader-rotating 1.2s linear infinite;
    }

    &.loader-two-sides-circle--inverted {
        &::after {
            border-color: $loader-color--inverted transparent $loader-color--inverted transparent;
        }
    }

    &.loader-two-sides-circle--grey {
        &::after {
            border-color: $loader-color--grey transparent $loader-color--grey transparent;
        }
    }

    &.loader-two-sides-circle--small {
        @include build-loader-size-with-after(8px, 2px, 2px);
    }

    &.loader-two-sides-circle--normal {
        @include build-loader-size-with-after(14px, 4px, 3px);
    }

    &.loader-two-sides-circle--large {
        @include build-loader-size-with-after(20px, 6px, 4px);
    }

    &.loader-two-sides-circle--xlarge {
        @include build-loader-size-with-after(40px, 8px, 4px);
    }
}

.loader-three-sides-circle {
    width: 75px;
    height: 75px;
    margin: 8px;
    border-width: 6px;
    border-style: solid;
    border-color: $loader-color $loader-color transparent $loader-color;
    border-radius: 50%;
    display: inline-block;
    animation: loader-rotating 1.2s linear infinite;

    &.loader-three-sides-circle--inverted {
        border-color: $loader-color--inverted $loader-color--inverted transparent $loader-color--inverted;
    }

    &.loader-three-sides-circle--grey {
        border-color: $loader-color--grey $loader-color--grey transparent $loader-color--grey;
    }


    &.loader-three-sides-circle--small {
        @include build-loader-size(12px, 2px, 2px);
    }

    &.loader-three-sides-circle--normal {
        @include build-loader-size(20px, 4px, 3px);
    }

    &.loader-three-sides-circle--large {
        @include build-loader-size(28px, 6px, 4px);
    }

    &.loader-three-sides-circle--xlarge {
        @include build-loader-size(47px, 8px, 4px);
    }
}

@keyframes loader-rotating {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
